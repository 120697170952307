// Colors
// Fonts
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
// Variables
@import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
// Flag Icon CSS
@import '~flag-icons/sass/flag-icons';
// Font Awesome
$fa-font-path: "../webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
// Bootstrap Table
@import "~bootstrap-table";
@import "~bootstrap-icons";

body {
    background: #ECF3F9;
}

.company-logo {
    height: 30px;
}

h1, h2, h3, h4 {
    color: #E87346;
}

.table {
    background-color: white;
}

.vertical-alignment-helper {
    display: table;
    height: 100%;
    width: 100%;
    pointer-events: none;
}

.vertical-align-center {
    /* To center vertically */
    display: table-cell;
    vertical-align: middle;
    pointer-events: none;
}

.modal-content {
    width: 80vw;
    /* For Bootstrap 4 - to avoid the modal window stretching full width */
    height: inherit;
    /* To center horizontally */
    margin: 0 auto;
    pointer-events: all;
    box-shadow: 0px 0px 15px #fff;
    border: #00145A solid 5px;
    border-radius: 20px;
}

.modal-body .row {
    padding: inherit;
}

.modal-header {
    border: unset;
}

.modal-title {
    width: 100%;
    font-weight: bold !important;
}

.close {
    border-radius: 10px;
    border: 2px solid $energicBlue;
    background-color: $energicBlue;
    position: relative;
    color: white;
}

.avatar-fig {
    text-align: center;
    background: #F5F5F5;
    border-radius: 20px;
    padding: 15px;

    img {
        width: 100%;
    }
}

.applications-table {
    h2 {
        padding-top: 1.5rem !important;
    }

    table {
        text-align: center;

        tr td:last-child {
            font-size: 25px;
        }
    }
}

.view.disabled {
    pointer-events: none;
    color: #666666;
}

.avatar-btn.disabled {
    pointer-events: none;
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.excel {
    float: right;
    margin-bottom: 15px;
}


footer {
    text-align: center;
    font-size: 0.9rem;
    background: rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
}